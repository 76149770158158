.hexColorPicker.react-colorful {
  @apply w-full h-full flex flex-col gap-y-4 mb-4;
}

.hexColorPicker.react-colorful .react-colorful__saturation {
  @apply border-none rounded min-h-[172px];
}

.hexColorPicker.react-colorful .react-colorful__saturation .react-colorful__interactive {
  @apply h-[172px];
}

.hexColorPicker.react-colorful .react-colorful__hue {
  @apply rounded-full min-h-4 h-4;
}

.hexColorPicker.react-colorful .react-colorful__saturation-pointer {
  @apply h-3 w-3 border-2;
}

.hexColorPicker.react-colorful .react-colorful__hue-pointer {
  @apply h-5 w-5 border-[4px];
}

.hexColorPicker.react-colorful .react-colorful__pointer {
  @apply shadow-xl;
}
