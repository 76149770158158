@import '../variables.scss';

.view {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    button.action {
      border-radius: 10px;
      box-shadow: 0 0 0;
      background-color: $primary;
      padding: 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      text-transform: none;
    }
  }
  .tabs {
    display: flex;
    border-bottom: 1px solid $lightGray;
    align-items: center;
    height: 30px;

    h2 {
      margin: 0;
      padding-bottom: 20px;
    }

    a {
      color: $lightGray;
      outline: none;
      font-weight: 500;
      text-decoration: none;
      padding: 0 15px 11px 15px;

      &.active {
        color: $primary;
        font-weight: 600;
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}

.selector-project {
  width: 324px;
  & .select-content {
    margin: 0;
  }
}

.projects {
  margin-top: 24px;
}
.projects-card {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 20px 4px 16px 20px;
  border-radius: 4px;
  &:hover {
    background: var(--primary-1, #f2eaff);
    cursor: pointer;
    & .projects-card-title {
      color: var(--primary-blue, theme('colors.indigo.700'));
    }
  }
}
.projects-card-title {
  font-size: 20px;
  color: var(--neutural-50, #717188);
}

.row-ended .row-content {
  opacity: 0.5;
}
