#theme {
  ///* Shadcn palette, remove in future */
  --radius: 0.5rem;

  /* Base Section */
  --base-accent: #F4F4F5;
  --base-accent-foreground: #18181B;
  --base-background: #FFFFFF;
  --base-border: #E4E4E7;
  --base-card: #FFFFFF;
  --base-card-foreground: #09090B;
  --base-destructive: #DC2626;
  --base-destructive-foreground: #FEF2F2;
  --base-foreground: #18181B;
  --base-input: #E4E4E7;
  --base-muted: #F5F5F5;
  --base-muted-foreground: #71717A;
  --base-popover: #FFFFFF;
  --base-popover-foreground: #09090B;
  --base-primary: #18181B;
  --base-primary-foreground: #FAFAFA;
  --base-ring: #000000;
  --base-ring-offset: #FFFFFF;
  --base-secondary: #F4F4F5;
  --base-secondary-foreground: #18181B;
  --base-chart-1: #008080;
  --base-chart-2: #2A9D90;
  --base-chart-3: #4682B4;
  --base-chart-4: #FFD700;
  --base-chart-5: #FFA500;
  --base-sidebar-background: #FFFFFF;
  --base-sidebar-foreground: #3F3F46;
  --base-sidebar-primary: #0000FF;
  --base-sidebar-primary-foreground: #FFFFFF;
  --base-sidebar-accent: #CCCCCC;
  --base-sidebar-accent-foreground: #18181B;
  --base-sidebar-border: #E5E7EB;
  --base-sidebar-ring: #A1A1AA;
  --base-black: #000000;
  --base-white: #FFFFFF;

  /* Alpha Section */
  --alpha-10: rgba(255, 255, 255, 0.9);
  --alpha-20: rgba(255, 255, 255, 0.8);
  --alpha-30: rgba(255, 255, 255, 0.7);
  --alpha-40: rgba(255, 255, 255, 0.6);
  --alpha-50: rgba(255, 255, 255, 0.5);
  --alpha-60: rgba(255, 255, 255, 0.4);
  --alpha-70: rgba(255, 255, 255, 0.3);
  --alpha-80: rgba(255, 255, 255, 0.2);
  --alpha-90: rgba(255, 255, 255, 0.1);

  &[data-theme='dark'] {
    /* Base Section */
    --base-accent: #000000;
    --base-accent-foreground: #FFFFFF;
    --base-background: #09090B;
    --base-border: #27272A;
    --base-card: #27272A;
    --base-card-foreground: #FAFAFA;
    --base-destructive: #7F1D1D;
    --base-destructive-foreground: #FEF2F2;
    --base-foreground: #FAFAFA;
    --base-input: #27272A;
    --base-muted: #F5F5F5;
    --base-muted-foreground: #A1A1AA;
    --base-popover: #FFFFFF;
    --base-popover-foreground: #09090B;
    --base-primary: #FAFAFA;
    --base-primary-foreground: #18181B;
    --base-ring: #FFFFFF;
    --base-ring-offset: #FFFFFF;
    --base-secondary: #27272A;
    --base-secondary-foreground: #FAFAFA;
    --base-chart-1: #0000FF;
    --base-chart-2: #FF0000;
    --base-chart-3: #FFA500;
    --base-chart-4: #800080;
    --base-chart-5: #008000;
    --base-sidebar-background: #1A1A1A;
    --base-sidebar-foreground: #FFFFFF;
    --base-sidebar-primary: #00008B;
    --base-sidebar-primary-foreground: #FFFFFF;
    --base-sidebar-accent: #444444;
    --base-sidebar-accent-foreground: #FFFFFF;
    --base-sidebar-border: #333333;
    --base-sidebar-ring: #CCCCCC;
    --base-black: #FFFFFF;
    --base-white: #000000;

    /* Alpha Section */
    --alpha-10: rgba(9, 9, 11, 0.9);
    --alpha-20: rgba(9, 9, 11, 0.8);
    --alpha-30: rgba(9, 9, 11, 0.7);
    --alpha-40: rgba(9, 9, 11, 0.6);
    --alpha-50: rgba(9, 9, 11, 0.5);
    --alpha-60: rgba(9, 9, 11, 0.4);
    --alpha-70: rgba(9, 9, 11, 0.3);
    --alpha-80: rgba(9, 9, 11, 0.2);
    --alpha-90: rgba(9, 9, 11, 0.1);
  }
}