@import '../../variables.scss';

$cardheight: 280px;
$footerHeight: 40px;
$imageHeight: calc($cardheight - $footerHeight);

.imageItem {
  height: $cardheight;
  margin-top: 32px;
  margin-right: 16px;
  border: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  position: relative;

  &.products {
    width: 240px;
    img {
      min-height: 80px;
      min-width: 80px;
      max-height: 60%;
      max-width: 60%;
    }
  }
  &.covers {
    width: 510px;
    img {
      width: 100%;
      height: $imageHeight;
    }
  }

  .imageHolder {
    height: $imageHeight;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      height: 50px !important;
      width: 50px !important;

      circle {
        stroke: $primary;
      }
    }
  }

  .imageFooter {
    height: $footerHeight;
    background-color: $lightGray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  a {
    color: white;
    text-decoration: none;
    outline: none;
  }
}

.assetType {
}

.asset-tag {
  background: #e0e0e0;
  color: $primary;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  position: absolute;
  left: 0;
  top: 20px;
}
