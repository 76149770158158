@import '../../variables.scss';

.input-container {
  position: relative;
}

.left-spinner input[type='number'] {
  direction: rtl;
  text-align: left;
  position: relative;
}

.left-spinner input[type='number']::-webkit-inner-spin-button,
.left-spinner input[type='number']::-webkit-outer-spin-button {
  margin: 0 10px;
}

.apply-button--container {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  box-sizing: content-box;

  button {
    width: 100%;
    background: $primary;
    text-transform: none;
    &:hover {
      background-color: $primary;
    }
  }
}

.search-dropdown {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 12px;
  &-header {
    font-size: 14px;
    font-weight: 600;
  }
}

.url-input {
  min-width: 100%;
}

.events-input {
  width: 40%;
}