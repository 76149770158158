body {
  font-family: 'Inter', 'Montserrat', serif;
}

button.google-login-button {
  --google-grey: #d4d8df;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: (var(--google-grey)) !important;
  }
}

.login-divider {
  margin: 10px;
  display: flex;
  justify-content: center;

  &__text {
    font-size: 12px;
    padding: 0 8px;
    background-color: white;
  }
}