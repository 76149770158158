@import '../../style/colors';

.actions-header {
  outline: none !important;
  &:active {
    outline: none !important;
  }
}

.file-name {
  font-size: 14px;
  font-weight: 400;
}

.file-size {
  color: #717188;
  font-size: 12px;
  font-weight: 500;
}

.close-icon {
  fill: #717188;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.price-points {
  &__errors-content-block {
    background: $neutral-10;
    border: 1px solid $neutral-30;
    border-radius: 4px;
    margin-top: 16px;
    padding: 8px 12px 8px 8px;
    max-height: 100px;
    overflow: auto;
    font-size: 14px;
  }

  &__error-message {
    margin-bottom: 8px;
  }

  &__toggle-visibility {
    color: $primary-30;
    cursor: pointer;
    margin-top: 8px;
  }
}

.MuiDialog-paper {
  padding: 16px;

  .dialog-header {
    h2 {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .MuiDialogContentText-root {
    color: black;
    font-weight: 400;
    font-size: 14px;
  }
}

.import-export-wrapper {
  display: flex;
  gap: 16px;
}

.import-error-modal .MuiDialog-paper {
  max-height: 400px;
}