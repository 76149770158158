@import '../../variables.scss';

#loginModal {
  width: 400px;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 30px rgb(0 0 0 / 0.2);
  position: relative;
  z-index: 2;

  .body {
    .input {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      label {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      input {
        height: 48px;
        line-height: 48px;
        padding: 0 10px;
      }

      &.short {
        margin-bottom: 10px;
      }
    }

    button {
      width: 100%;
      height: 48px;
    }
  }
}

.login {
  $login-blue: theme('colors.indigo.700');

  &__header {
    font-size: 24px;
    font-weight: 700;

    h1 {
      margin-bottom: 10px;
    }

    &__text {
      margin-top: 1em;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }

  &__actions {
    color: $login-blue;
    font-size: 14px;
    text-decoration-color: $login-blue;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__input {
    margin-bottom: 20px;
    label {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}