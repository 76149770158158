@import '../../variables.scss';
@import '../../style/typography';

.viewHeader {
  .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs-state {
    margin: 0 3rem;
  }

  .headline {
    display: flex;
    align-items: center;

    .headline_text {
      @include typo-title-large;
    }

    .backButton {
      padding: unset;
      margin-right: 5px;
      cursor: pointer;
      color: $text;
    }
  }
}