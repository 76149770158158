@import '../../variables.scss';

h1,
h2,
h3,
p {
  color: $text;
}

.login-alert {
  width: 400px;
  opacity: 0;
  transition: 0.18s all;
  z-index: 100;
  position: absolute;
  top: 102%;

  &.visible {
    opacity: 1;
  }
}

#loginPage {
  height: 100vh;
  width: 100vw;
  background-color: #18181B;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-form-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: relative;
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

}

.logo {
  width: 300px;
}

@media (max-width: $mobile-width) {
  #loginPage {
    background-size: 130vw 100vh;
  }
}