@import '../../variables.scss';

.view {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  color: black;
}

.blank-image,
.blank-logo {
  width: 150px;
  height: 150px;
  background: $lightGray;
  margin-top: 1em;
  border-radius: 0.5em;
}

.blank-logo {
  width: 350px;
}

.MuiTab-root {
  font-family: 'Inter', 'Montserrat', serif;
  text-transform: none !important;
}

.MuiButtonBase-root {
  font-family: 'Inter', 'Montserrat', serif !important;
}

.main-grid {
  max-width: 750px;
}

.eventsTable .MuiDataGrid-root {
  margin-top: 0;
}
