.integraion-profile-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;

  .MuiStack-root.input-headerBlock.formContent-input-fieldTitle {
    display: none;
  }

  div {
    height: 32px;
    width: 100%;
  }

  svg {
    cursor: pointer;
    height: 32px;
    margin-right: 8px;
  }
}

.text-p-m {
  max-width: 550px;
}